<template>
    <client-page>
        <div id="contents">
            <v-container>
                <div class="title-wrap title-wrap--line">
                    <h2 class="font-42">문의 및 제휴</h2>
                </div>

                <form-input code="inquiry-partner" skin="inquiry-partner"></form-input>

            </v-container>
        </div>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        ClientPage,
        FormInput
    },
};
</script>
